/* Set the background color to orange */
body {
  background-color: #F7D1A1;
  margin: 0;
  font-family: Arial, sans-serif;
}

/* Center the login form on the page */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Style the login form */
.login-form {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-form h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.login-form h3 {
  margin-bottom: 20px;
  font-size: 35px;
  font-family: "Lucida Console", "Courier New", monospace;
  align-items: center;
  text-align: center;
  color: #333;
}

.login-form input {
  width: 94%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-form button {
  width: 100%;
  padding: 10px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #555;
}

.welcome-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

.welcome-container img {
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 75vw; /* 50% of the viewport width */
  height: auto; /* Maintain aspect ratio */
  max-width: 100%; /* Ensure it doesn't exceed its container */
  max-height: 100% /* Maximum height is 50% of the viewport height */
}

